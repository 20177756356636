$(document).ready(function() {

  // menu
  $("#menuBtn").on("click", function() {
    $(this).toggleClass("menu__btn--clicked");
    if ($(this).hasClass("menu__btn--clicked")) {
      $("body").css("overflow", "hidden");
      $("#menu").addClass("menu--opened");
      setTimeout(function() {
        $(".menu__menu").css("overflow-y", "auto");
      }, 1000);
    } else {
      $("body").css("overflow", "auto");
      $("#menu").removeClass("menu--opened");
    }
  });
});
