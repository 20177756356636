// Font Awesome
// Font icon set

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import {
  faSearch,
  faTimes,
  faAngleRight,
  faPhoneAlt,
  faAngleLeft,
  faMapMarkerAlt,
  faEnvelope,
  faPrint,
  faPlus,
  faMinus,
  faAngleDown,
  faLongArrowAltRight,
  faLongArrowAltLeft,
  faGift,
  faHeart,
  faHammer,
  faPaperPlane,
  faHome,
  faShoppingCart,
  faHandPaper,
  faTrash,
  faCalendarAlt,
  faClock,
  faSort
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faPinterestP,
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faYoutube
} from "@fortawesome/free-brands-svg-icons";

$(document).ready(function() {
  library.add(
    faSearch,
    faTimes,
    faAngleRight,
    faPhoneAlt,
    faAngleLeft,
    faMapMarkerAlt,
    faEnvelope,
    faFacebook,
    faPinterestP,
    faInstagram,
    faYoutube,
    faTwitter,
    faLinkedinIn,
    faPrint,
    faPlus,
    faMinus,
    faAngleDown,
    faLongArrowAltRight,
    faLongArrowAltLeft,
    faGift,
    faHeart,
    faHammer,
    faPaperPlane,
    faHome,
    faShoppingCart,
    faHandPaper,
    faTrash,
    faCalendarAlt,
    faClock,
    faSort
  );

  dom.i2svg();
});
